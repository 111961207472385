<template>
  <div class="flex-container non-scrollable">
    <div v-if="independent" class="flex justify-content-between mb-3 align-items-center gap-4">
      <NameHeader :independent="independent" :showSubtitle="false" />
    </div>
    <declare-paie/>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import NameHeader from '@/components/NameHeader'
import DeclarePaie from "@/views/Pages/Porte/DeclarePaie";

export default {
  name: "Declaration",
  components: {NameHeader, DeclarePaie },
  computed: {
    ...mapState({
      sideContent: state => state.sidebar.sideContent,
    }),
    ...mapGetters({
      independent: 'independent/getIndependent',
      independents: 'independent/getIndependents'
    }),
  },
  watch: {
    '$route.query.idIndependent': {
      handler (val) {
        if (val) {
          this.$store.dispatch('independent/getOneIndependent', val)
        }
      },
      deep: true
    }
  },
  beforeMount () {
    this.$store.dispatch('sidebar/changeMenu', 'Paie')
  },
  created () {
    this.$store.dispatch('independent/getOneIndependent', this.$route.query.idIndependent)
    this.$store.dispatch('independent/getAllIndependents')
  },
  methods: {
    // todo: check if it still work
    // clearIndependent () {
    //   this.$router.push({name: 'PaiePortes', query: {}})
    //   this.$store.commit('independent/SET_INDEPENDENT', null)
    // },
  }
}
</script>

<style scoped>

</style>
